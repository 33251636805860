@use "sass:map";
/* You can add global styles to this file, and also import other style files */

$warning: #E59502;
$themeTextColor: #a5848f;
$primary: #00A599;
$info: #D09A3C;
$enable-negative-margins: true;

.nav-link:hover {
  background-color: #efefef !important;
}

.nav-link.active {
  box-shadow: 4px 0px inset $primary;
  color: #00A599 !important;

  & .fw-bold {
    color: $primary !important;
  }
}

@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import 'node_modules/remixicon/fonts/remixicon.css';
@import 'node_modules/animate.css/animate.min.css';
@import "@ng-select/ng-select/themes/default.theme.css";
@import 'ngx-toastr/toastr';
@import 'ngx-toastr/toastr-bs5-alert';

body {
  margin: 0px;
  background-color: #EBEEF3;
  height: 100%;
}

.cursor-pointer {
  cursor: pointer !important;
}

.loading {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1000;
  background-color: white;
}

.loading img {
  max-height: 100px;
}

#cardimage {
  max-width: 300px;
}

#quiz,
#simplify,
#requirements {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 90%;
  height: 100px;
  width: 100px;
  margin-bottom: 5px;
  display: inline-block;
  border-radius: 5%;
}

#quiz:hover,
#simplify:hover,
#requirements:hover {
  background-color: #F0F4FA;
}

.shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

html,
body {
  height: 100%;
}

app-bot {
  height: 100%;
}

.form-control-plaintext {
  border-radius: 0px;
}

.form-control-plaintext:hover {
  background-color: #F9F9F9 !important;
  border-bottom-color: #D2D2D2;
}

.bg-ai-help {
  background-color: #00A599;
}

a.nav-link {
  &:hover {
    color: darken($themeTextColor, 30%);
  }

  color:$themeTextColor
}

.btn.btn-primary {
  color: #ffffff !important;
}

.feature {
  white-space: nowrap;
  margin: 0px 2px;
  padding: 0px 2px
}

.feature:hover {
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  cursor: help;
}

@media screen and (max-height: 300px) {

  nav.navbar,
  #messages {
    display: none;
  }

  #chat-container {
    display: none;
  }
}

.ri-spinner {
  display: inline-block;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.avatar-pulse {
  background: rgb(255, 255, 255);
  color: $danger;
  border-radius: 50%;
  margin: 10px;
  height: 20px;
  width: 20px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.rotate {
  animation: rotation 1s infinite ease-in-out;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.message code {
  white-space: break-spaces;
  background-color: #000;
  display: block;
  color: #fff;
  border-radius: 5px;
  padding: 2em;
}

markdown>p:last-child {
  margin-bottom: 0px;
}

.tooltip-inner {
  background-color: #ffff !important;
  opacity: 1 !important;
  color: #000 !important;
  border-radius: 0.375rem;
  border: 1px solid #0003;
  padding: 0.75rem;
  text-align: left;
  font-size: 14px;
  box-shadow: 0px 1px 5px lightgray;
}

.tooltip.show {
  opacity: 1;
}

.bs-tooltip-top .tooltip.top,
.bs-tooltip-bottom .tooltip.bottom,
.bs-tooltip-start .tooltip.start,
.bs-tooltip-end .tooltip.end,
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before,
.tooltip.top,
.tooltip.bottom,
.tooltip.start,
.tooltip.end,
.tooltip-arrow {
  display: none;
}

.ngx-slider .ngx-slider-pointer {
  background-color: $primary !important;
}

.ngx-slider .ngx-slider-pointer.ngx-slider-active {
  outline-color: var(--bs-primary-bg-subtle) !important;
}

.ngx-slider .ngx-slider-pointer.ngx-slider-active:after {
  background-color: var(--bs-primary-bg-subtle) !important;
}

#botTags {
  background-color: #fff !important;

  .ng-select-container {
    border: 0;
  }
}

.ng-select {
  .ng-value {
    background-color: var(--bs-primary-bg-subtle) !important;
  }

  .ng-value-icon {
    border-color: var(--bs-primary-border-subtle) !important;
  }

  .ng-value-icon:hover {
    background-color: #87c4bf !important;
  }
}

.card-header.bg-primary {
  background-color: #CCEDEB !important;
}

.card-light-danger {
  border-top: 4px solid rgba(255, 122, 122, 0.673) !important;
}

.card-light {
  border-top: 4px solid #CCEDEB;

  .card-header {
    background-color: transparent;
    border: 0px;
  }
}

app-root:has(.server-tag):has(app-multi-bot) {
  .server-tag {
    display: none !important;
  }
}

* {
  font-size: inherit;
  --bubble-assistant-bg-color: #E5E5EA;
  --bubble-assistant-text-color: #000000;
  --bubble-user-bg-color: #0B93F6;
  --bubble-user-text-color: white;
  --chatbot-bg-color: #fff;
  --chatbot-theme-color: #00A599;
}

.border-radius-0 {
  border-radius: 0px;
}

rag-body-layout h3,
rag-body-layout h5 {
  color: #666;
}

rag-body-layout {
  #sidebar span {
    color: #666;
  }

  layout-wrapper {
    overflow-x: hidden;
  }

  #content-outlet {

    .card span:not(.ng-value-label, .ng-value-icon, [class^="ͼ"]),
    .default-text {
      color: #666;
    }
  }
}

.form-control {
  background-color: #ededed !important;
}

/* golbales card style für die content cards*/
.card.border-0 {
  background-color: #fff;

  .card-body {
    padding-left: 0rem;
    padding-right: 0rem;
  }
}

body,
.wrapper {
  background-color: #fff !important;

  input,
  textarea,
  code-editor,
  select,
  div {
    &.form-control {
      background-color: #fff !important;
      border-width: 2px;
      color: #000 !important;

      &.rag-aitext {
        padding-left: 40px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2326B3A8'%3E%3Cpath d='M17.0007 1.20825 18.3195 3.68108 20.7923 4.99992 18.3195 6.31876 17.0007 8.79159 15.6818 6.31876 13.209 4.99992 15.6818 3.68108 17.0007 1.20825ZM8.00065 4.33325 10.6673 9.33325 15.6673 11.9999 10.6673 14.6666 8.00065 19.6666 5.33398 14.6666.333984 11.9999 5.33398 9.33325 8.00065 4.33325ZM19.6673 16.3333 18.0007 13.2083 16.334 16.3333 13.209 17.9999 16.334 19.6666 18.0007 22.7916 19.6673 19.6666 22.7923 17.9999 19.6673 16.3333Z'%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 25px;
        background-position: 5px center;

        &:focus {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2326B3A8'%3E%3Cpath d='M17.0007 1.20825 18.3195 3.68108 20.7923 4.99992 18.3195 6.31876 17.0007 8.79159 15.6818 6.31876 13.209 4.99992 15.6818 3.68108 17.0007 1.20825ZM8.00065 4.33325 10.6673 9.33325 15.6673 11.9999 10.6673 14.6666 8.00065 19.6666 5.33398 14.6666.333984 11.9999 5.33398 9.33325 8.00065 4.33325ZM19.6673 16.3333 18.0007 13.2083 16.334 16.3333 13.209 17.9999 16.334 19.6666 18.0007 22.7916 19.6673 19.6666 22.7923 17.9999 19.6673 16.3333Z'%3E%3C/path%3E%3C/svg%3E");
        }
      }
    }
  }
}

.layout-wrapper .container {
  max-width: 1500px !important;
}

.btn-logout {
  margin: 10px !important;
  padding: 5px;
}

.table-hover tbody tr:hover td {
  background-color: #fcfcfc !important;
}

.btn-outline-primary:hover {
  color: #fff;
}

.full-width-table-wrapper {
  margin-left: calc(map.get($spacers, 5) * -1);
  margin-right: calc(map.get($spacers, 5) * -1);

  table tr {

    & td:first-child,
    & th:first-child {
      padding-left: map.get($spacers, 5);
    }

    & td:last-child,
    & th:last-child {
      padding-right: map.get($spacers, 5);
    }
  }
}

.ng-select-container {
  background-color: #fff !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled,
.ng-option.ng-option-marked.ng-star-inserted,
.ng-option.ng-option-marked {
  background-color: #fff !important;
}

.btn-primary:active,
.btn-outline-primary:active {
  color: #cef0ed !important;
}

.dropdown-menu button {
  &.text-primary:active {
    color: #cef0ed !important;
  }

  &.text-danger:active {
    background-color: var(--bs-danger) !important;
    color: #ffc4c4 !important;
  }
}

.dropdown-menu div {
  &.text-primary:active {
    color: #cef0ed !important;
  }

  &.text-danger:active {
    background-color: var(--bs-danger) !important;
    color: #ffc4c4 !important;
  }
}

.bg-selected-row {
  td {
    background-color: transparent;
  }

  background-color: #efefef !important;
}


.btn.playT2S {
  &:hover {
    color: #d3d3d3;
    cursor: pointer
  }

  color: #a9a9a9;
}

.btn.loadT2S {
  &:hover {
    color: #d3d3d3;
    cursor: pointer
  }

  color: #a9a9a9;
}

.message-bubble {
  .ri-close-line.text-danger {
    visibility: hidden;
  }

  .ri-bug-2-line {
    visibility: hidden;
  }
}

.message-bubble:hover {
  .ri-close-line.text-danger {
    visibility: visible;
    cursor: pointer;
  }

  .ri-bug-2-line {
    visibility: visible;
    cursor: pointer;
    color: #a9a9a9;

    &:hover {
      color: #d3d3d3;
    }
  }
}

.callout {
  padding: 1rem;
  margin: 1rem 0;
  border: 1px solid #d8dbe0;
  border-left-width: calc(1px * 4);
}

.callout-info {
  border-left-color: $info !important;
  border-radius: 0px;
}

.fakelink {
  cursor: pointer;
  color: $primary;
  text-decoration: underline;
}

.helpTooltip {
  .tooltip-inner {
    max-width: 500px;
  }
}

.input-group-ai-suggest input {
  border-right-width: 0px;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

#endbotBody {
  div.btn {
    margin-top: 1rem;
  }

  h1 {
    color: #666666;
  }
}

#endbotRedirect {
  margin-top: 1rem;
}

.pointer-events-none {
  pointer-events: none;
}

.tooltipStylePushToTalk .tooltip-inner {
  min-width: 200px;
  color: #fff;
}

.apexcharts-tooltip-marker::before {
  font-size: 16px !important;
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ri-loader::before {
  display: inline-block;
  animation: spin 2s linear infinite;
}

.text-blur {
  color: transparent;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  transition: color 0.5s ease;
}

.text-blur:hover {
  color: black;
  text-shadow: unset;
}